<div class="sticky-container">
  <a routerLink="/app/home" class="button-link">
    <mat-icon color="primary">chevron_left</mat-icon>{{'station.search_other' | translate}}
  </a>
</div>

<div class="main-content">
  <ng-container *ngIf="station">
    <mat-card class="w-100">
      <mat-card-header>
        <mat-card-title class="d-flex align-items-center station-title" [ngClass]="station.status.code">
          <div class="mr-2" [ngClass]="'status-bullet ' + station.status.code"></div>
          <small>
            {{'station.status' | translate}}&nbsp;{{'entities.station_statuses.' + station.status.code |translate}}
            <ng-container *ngIf="station.status.code === 'under_construction' || station.status.code === 'closed'">
              <br>
              <ng-container *ngIf="station.info.status_start_date && station.info.status_end_date">
                {{'station.under_construction.date_from' | translate}}
                <span class="font-weight-bold">{{station.info.status_start_date | date:'dd/MM/YYYY'}}</span>
                {{'station.under_construction.date_to' | translate}}
                <span class="font-weight-bold">{{station.info.status_end_date | date:'dd/MM/YYYY'}}</span>
              </ng-container>
              <ng-container *ngIf="station.info.status_start_date && !station.info.status_end_date">
                {{'station.under_construction.date_from_without_to' | translate}}
                <span class="font-weight-bold">{{station.info.status_start_date | date:'dd/MM/YYYY'}}</span>
              </ng-container>
              <ng-container *ngIf="!station.info.status_start_date && station.info.status_end_date">
                {{'station.under_construction.date_to_without_from' | translate}}
                <span class="font-weight-bold">{{station.info.status_end_date | date:'dd/MM/YYYY'}}</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="station.status.code === 'permanently_closed'">
              <ng-container *ngIf="station.info.status_start_date">
                {{'station.under_construction.date_from_without_to' | translate}}
                <span class="font-weight-bold">{{station.info.status_start_date | date:'dd/MM/YYYY'}}</span>
              </ng-container>
            </ng-container>
          </small>
        </mat-card-title>

        <button mat-mini-fab (click)="updateFavoriteStation()" class="favorite-btn">
          <mat-icon class="star" [ngClass]="{'is-favorite': station.is_favorite === true}">
            {{station.is_favorite? 'star' : 'star_outline'}}
          </mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <div class="station-head-info">
          <div class="text-left"><img [src]="station.info.brand?.logo" *ngIf="station.info.brand?.logo" height="50">
          </div>
          <!-- <div class="text-center">{{station.code_implant}}</div> -->
          <h2 class="mb-0 pb-0 text-center SizeH2CodeImplant">{{ station.code_implant}}</h2>
          <div class="text-right">
            <div class="chip" *ngIf="station.info.sales_channel?.label">{{station.info.sales_channel?.label}}</div>
            <div class="chip mt-2" *ngIf="station.info.sca?.label">{{station.info.sca?.label}}</div>
          </div>
        </div>
        <div class="text-center mt-4">
          <h2 class="mb-0 pb-0">{{ station.info.site_name}}</h2>
          <h3 class="mb-0 pb-0">{{ station.info.segmentation?.label}}</h3>
        </div>
      </mat-card-content>
      <mat-card-actions class="d-flex justify-content-center">
        <ng-container *ngIf="!isCarUser() && !isVisitorOrVisitorDCM() && ctcs.length == 0 && !showIncidents; else hideIncidentsTemplate" >
          <button mat-flat-button color="primary" class="text-center mr-1" (click)="getIncidents()">
            {{'station.incidents_history' | translate}}
          </button>
        </ng-container>
        <ng-template #hideIncidentsTemplate>
          <button *ngIf="!isCarUser() && !isVisitorOrVisitorDCM()" mat-flat-button color="primary" class="text-center mr-1" (click)="hideIncidents()">
            {{'station.hide_incidents' | translate}}
          </button>
        </ng-template>
        <ng-container *ngIf="isCarUser(); else notCarTemplate">
          <button mat-flat-button color="primary" class="text-center ml-1" (click)="declareCarIncident()">
            {{'station.create_car_incident' | translate}}
          </button>
        </ng-container>
        <ng-template #notCarTemplate>
          <button mat-flat-button color="primary" class="text-center ml-1" (click)="declareIncident()" *ngIf="!isVisitorOrVisitorDCM()">
            {{'station.create_incident' | translate}}
          </button>
        </ng-template>
      </mat-card-actions>
    </mat-card>

    <mat-card class="w-100 my-3" *ngIf="station.specifics?.length > 0">
      <h3>{{'station.specifics' | translate}}</h3>
      <div class="specifics-container">
        <div class="chip specific-item" *ngFor="let specific of station.specifics" #tooltip="matTooltip"
          matTooltip="{{specific.description}}" matTooltipPosition="above" (click)="tooltip.show()">
          {{specific.label}}</div>
      </div>
    </mat-card>

    <mat-card class="w-100 mt-3" *ngIf="showIncidents">
      <h3>{{'station.incidents_history' | translate}}</h3>
      <div class="noIncidentsfound" *ngIf="ctcs.length == 0">{{'station.no_incidents_found'|translate}}</div>
      <app-history-card [ctc]="ctc" *ngFor="let ctc of ctcs"></app-history-card>
    </mat-card>

    <div class="w-100 my-3 p-0">
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <div>{{'station.tabs.operator.title'|translate}}</div>
          </ng-template>
          <ng-template matTabContent>
            <mat-card>
              <app-operator-tab [stationInfo]="station.info"></app-operator-tab>
            </mat-card>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div>{{'station.tabs.contacts'|translate}}</div>
          </ng-template>
          <ng-template matTabContent>
            <mat-card>
              <app-contacts-list [contacts]="station.contacts" (clickToCalled)="clickToCall($event, false)">
              </app-contacts-list>
            </mat-card>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="!isVisitor()">
          <ng-template mat-tab-label>
            <div>{{'station.tabs.providers'|translate}}</div>
          </ng-template>
          <ng-template matTabContent>
            <mat-card>
              <app-providers-list [providers]="getProviderListForCurrentRole()"
                (clickToCalled)="clickToCall($event, true)">
              </app-providers-list>
            </mat-card>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>

  </ng-container>
</div>
