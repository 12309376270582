import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { StationInfo } from 'src/app/models/station-info.model';

@Component({
  selector: 'app-operator-bottom-sheet',
  templateUrl: './operator-bottom-sheet.component.html',
  styleUrls: ['./operator-bottom-sheet.component.scss']
})
export class OperatorBottomSheetComponent implements OnInit {
  phone: string;
  title: string;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<OperatorBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public providerData: { stationInfo: StationInfo, managerNumber: number }
  ) {
    this.phone = (providerData.managerNumber === 1)
      ? providerData.stationInfo.manager_phone
      : providerData.stationInfo.manager_2_phone;
    this.title = (providerData.managerNumber === 1)
      ? providerData.stationInfo.manager_first_name + ' ' + providerData.stationInfo.manager_last_name
      : providerData.stationInfo.manager_2_first_name + ' ' + providerData.stationInfo.manager_2_last_name;
  }

  ngOnInit(): void {
  }

  clickToCall(channel: string): void {
    this.bottomSheetRef.dismiss({ channel });
  }

  isMobilePhone(phone: string): boolean {
    return phone.startsWith('06') || phone.startsWith('07');
  }
}
