<div class="items-list">
  <div class="item">
    <div class="d-flex justify-content-between align-content-end">
      <div class="details-row mt-1">
        <div class="text text-bold">
          {{'station.tabs.operator.title' | translate}}
        </div>
      </div>
      <div class="item-icons">
        <ng-container *ngIf="stationInfo.phone">
          <a target="_blank" [href]="'tel:'+stationInfo.phone" (click)="clickToCall('phone', true)">
            <mat-icon>phone</mat-icon>
          </a>
        </ng-container>
        <ng-container *ngIf="stationInfo.email">
          <a target="_blank" [href]="'mailto:'+stationInfo.email" (click)="clickToCall('email', true)">
            <mat-icon>email</mat-icon>
          </a>
        </ng-container>
      </div>
    </div>
    <div class="d-flex justify-content-between align-content-end">
      <div class="details-row mt-1">
        <div class="text light" [matTooltip]="stationInfo.client_name">{{stationInfo.client_name}}</div>
      </div>
    </div>
  </div>

  <div class="item" *ngIf="stationInfo.establishment_date">
    <div class="details-row mt-1">
      <div class="text text-bold">
        {{'station.tabs.operator.establishment_date' | translate}}
      </div>
      <div class="text light">
        {{stationInfo.establishment_date | date :'dd/MM/y'}}
      </div>
    </div>
  </div>


  <div class="item" *ngIf="stationInfo.manager_last_name">
    <div class="d-flex justify-content-between align-content-end">
      <div class="details-row mt-1">
        <div class="text text-bold">
          {{'station.tabs.operator.manager' | translate}}
        </div>
        <div class="text light">
          {{stationInfo.manager_first_name}} {{stationInfo.manager_last_name}}
        </div>
      </div>
      <div class="item-icons">
        <div class="fake-button-link">
          <mat-icon class="pointer" (click)="openOperatorBottomSheet(1)">contacts</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="item" *ngIf="stationInfo.manager_2_last_name">
    <div class="d-flex justify-content-between align-content-end">
      <div class="details-row mt-1">
        <div class="text text-bold">
          {{'station.tabs.operator.manager_2' | translate}}
        </div>
        <div class="text light">
          {{stationInfo.manager_2_first_name}} {{stationInfo.manager_2_last_name}}
        </div>
      </div>
      <div class="item-icons">
        <div class="fake-button-link">
          <mat-icon class="pointer" (click)="openOperatorBottomSheet(2)">contacts</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="item">
    <div class="d-flex justify-content-between align-content-end">
      <div class="details-row mt-1">
        <div class="text text-bold">
          {{'station.tabs.operator.location' | translate}}
        </div>
      </div>
      <div class="item-icons">
        <ng-container *ngIf="stationInfo.latitude && stationInfo.longitude">
          <a target="_blank"
            [href]="'https://www.google.com/maps/search/?api=1&query='+stationInfo.latitude+','+stationInfo.longitude + '&z=19'">
            <mat-icon>place</mat-icon>
          </a>
        </ng-container>
      </div>
    </div>

    <div class="d-flex justify-content-between align-content-end">
      <div class="details-row mt-1">
        <div class="text light">{{stationInfo.street}}</div>
        <div class="text light">{{stationInfo.zipcode}} {{stationInfo.city}}</div>
      </div>
    </div>
  </div>

  <div class="item" *ngIf="stationInfo.station_map">
    <div class="d-flex justify-content-between align-content-end">
      <div class="details-row mt-1">
        <div class="text text-bold">
          {{'station.tabs.operator.station_map' | translate}}
        </div>
      </div>
      <div class="item-icons">
        <div class="fake-button-link">
          <mat-icon class="pointer" (click)="openLink(stationInfo.station_map)">map</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="item" *ngIf="stationInfo.sensitivity_sheet">
    <div class="d-flex justify-content-between align-content-end">
      <div class="details-row mt-1">
        <div class="text text-bold">
          {{'station.tabs.operator.sensitivity_sheet' | translate}}
        </div>
      </div>
      <div class="item-icons">
        <div class="fake-button-link">
          <mat-icon class="pointer" (click)="openLink(stationInfo.sensitivity_sheet)">assignment</mat-icon>
        </div>
      </div>
    </div>
  </div>

</div>
